
:root {
  --primary: #000000;
  --hover: #ef1442;
  --secondary: #000;
  --bg-color: #fbf9f4;
  --disabled-button: #f15778;
  --width: 260px;
}

*{
  text-decoration: none !important;
}

.btn-primary {
  color: #fff;
  background-color: var(--primary);
  border: 2px solid var(--primary);
  box-shadow: none;
  border-radius: 10px;
  padding: 6px;
}
.btn-primary:hover {
  border: 1px solid var(--primary);
  color: var(--primary);
  background-color: rgb(255, 255, 255);
}
.btn-secondary {
  color: var(--primary);
  background-color: #fff;
  border: 2px solid var(--primary);
  box-shadow: none;
  border-radius: 10px;
  padding: 6px;
}
.btn-secondary:focus {
  color: var(--primary);
  background-color: #fff;
  border: 2px solid var(--primary);
  box-shadow: none;
  border-radius: 10px;
  padding: 6px;
}
.btn-secondary:hover {
  color: #fff;
  background-color: var(--primary);
}
.btn-loader {
  color: #fff;
  background-color: var(--primary);
  border: 2px solid var(--primary);
  box-shadow: none;
  border-radius: 10px;
  padding: 6px;
}
.btn-loader:hover {
  color: #fff;
  background-color: var(--primary);
  border: 2px solid var(--primary);
  box-shadow: none;
  border-radius: 10px;
  padding: 6px;
}

.btn-full {
  width: 100%;
}
.form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: white !important;
  color: white !important;
}
.color-primary {
  color: var(--primary);
}
.hyperlink {
  color: #0d6efd;
  cursor: pointer;
}

.upload_file {
  display: block;
  padding: 0.8rem 1.5rem !important;
  border: 1px dotted #ffffff !important;
  border-radius: 10px !important;
}

.table_main {
  padding: 30px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 20px 20px 60px #cdcbcb, -20px -20px 60px #ffffff;
}


.flex-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.flex-items:nth-child(1) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

/* Custom CSS Table Loaer */
.table-loader thead tr {
  padding: 12px 10px;
  text-align: left;
}
.table-loader tbody tr {
  padding: 12px 10px;
  vertical-align: top;
}
.table-loader span {
  width: calc(100% - 5px);
  height: 15px;
  border-radius: 3px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  /* width: 100%; */
  animation: shine-lines-table 1.6s infinite linear;
  display: inline-block;
  min-width: 230px;
  /* max-width: 200px; */
}
.table-loader span.sub-temp {
  width: calc(100% - 50%);
  margin-top: 5px;
}
@keyframes shine-lines-table {
  0% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
}
.btn-danger {
  border-radius: 10px !important;
}
.search-container input {
  width: 40%;
  background-color: #fff !important;
  padding-left: 30px;
}
.search-container {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-container .span1 {
  position: relative;
  left: 25px;
}
.search-container .span2 {
  cursor: pointer;
  padding: 0px 4px;
  border-radius: 2px;
  background-color: #fbf9f4;
  position: absolute;
  right: 15px;
}
.search-container .span2:hover {
  background-color: #e8e8e8;
  background: linear-gradient(#640475a4, rgba(255, 255, 255, 0.322));
}

/* Theme sidebar width */
/********************/
.sidebarWidth{
  width: var(--width)
}
.sidebarColor{
  background: var(--bg-color);
}

/* .pageWrapper {
  min-height: 100vh;
}
.contentArea {
  flex-grow: 1;
  width: 70%;
}
.wrapper {
  max-width: 1300px;
  margin: 0 auto;
} */


.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  /* &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  } */
}
.button-group .btn {
  margin: 3px;
}

.side-btn{
  background: var(--secondary) !important;
}

.card-hover:hover {
  margin-top: -20px !important; 
  transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: 5px 5px 15px 1px rgb(117, 117, 117);
}

.btn-hover:hover {
  background-color: #640475 !important;
  color: white !important;
}

.css-12jo7m5 {
  background-color: #25476A;
  color: white !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;
}

.FIlterSize {
  width: 50%;
}



@media only screen and (max-width: 750px) {
  .FIlterSize {
    width: 100%;
  }
}