/* 
#sidebarMenu{
    position: relative;
    height: 100vh; 
    overflow-y: auto;  
}

 .mobile-menu {
    z-index: 1;
} 

#sidebarMenu .log-out{
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}



#sidebarMenu .nav-link{
    color: white ;
    border-radius: 50px;
    margin: 5px 0;
    font-size: 16px;
    font-weight: 500;
    
   
}

#sidebarMenu .nav-link:hover{
    color: black ;
    border-radius: 50px;
    background-color: white;
   
   
}
.nav-item .active{
    background: slateblue !important;
}


@media (max-width:768px){
    .mobile-menu{
        z-index: 5;
    }
} */


/* .sidebarArea {
    flex-shrink: 0;
    width: var(--width);
    background-color:var(--bg-color);
 
  }
  .sidenav-bg:hover {
    background-color: black;
    border-radius:5px;
  }
  .sidebarArea img{
    width: 80%;
  }
  
  @include media-breakpoint-down(lg) {
    .sidebarArea {
      position: fixed;
      height: 100%;
      overflow: auto;
      z-index: 1;
      margin-left: -260px;
      transition: 0.2s ease-in;
    }
    .showSidebar{
        margin-left: 0px;
    }
  }

  @media (max-width:980px) {
     .sidebarArea{
        width: 100%;
        text-align: center;
     }
     .sidebarArea img{
        width: 20%;
     }
  }
   */
/* 
  margin-left: -$sidebarWidth;
 
  &.showSidebar {
    margin-left: 0px;
  } */

  .sidebar-wrapper{
    position:absolute;
    left:1%;
    top:1%;
    z-index: 999;
  }

  .side-btn{
    background: #0D6EFD !important;
  }