.main-pagination{
    display: flex;
    margin-top: 1%;
}
.main-pagination button{
    color: var(--bg-color);
    background-color: var(--primary);
    outline: none;
    margin: 1px;
    height : fit-content;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
}
.main-pagination button:hover{
    background-color: var(--primary);
}

.btn_paginate
{
    background-color: var(--primary) !important;
}