
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: var(--bg-color);
}

.card {
    border-radius: 10px;
    padding: 43px 26px;
    width: 400px;
    
}

.abt {
    font-size: 15px;

}

.inputbox {
    margin-top: 12px;
    position: relative
}

.inputbox input {
    border: 2px solid #eee;
    padding: 8px;
    border-radius: 5px;
}

.inputbox input:focus {
    color: #495057;
    background-color: #fff;
    border-color: none;
    outline: 0;
    box-shadow: none;
    border: 1px solid var(--primary)
}

.inputbox span{

    font-size: 12px;
}

.inputbox a{

    font-size: 12px;
    text-decoration: none;
}

.input-tag {
    position: absolute;
    top: 27px;
    left: 7px;
    width: 32px
}

.proceed button {
 
    font-size: 15px
}

.form-check-label {
    font-size: 12px
}
.form-check-input {
    width: 16px;
    height: 16px;
    margin-top: 6px;
}
.logo-purpose{
    color: var(--primary);
    font-size: 30px;
}
.below-text{
    font-size: 26px;
    font-weight: 400;
}