/* .header-wrapper {
    width: 100%;
    background: #212529;
    height: 80px;
}

.navbar {
    position: fixed;
    width: 100%;
    height: inherit;
    background: #000;
}

.navbar .navbar-brand img{
    width: 100%;
    height: 50px;
} */
 
 /* .navbar .navbar-brand{
    width: 0%;
 }

 .navbar .navbar-brand img{
    width: 30%;
 } */
 /* .navbar-nav{
     padding: 10px 5px;
     height: 100%;
 }
 
 .navbar-nav .nav-item{
     display: flex;
     justify-content: space-between;
     align-items: center;
 }
 
 .nav-item .dropdown{
   
     
    
    
 }
 .navbar-nav .dropdown-menu{
    position: absolute;
    left: 80vw;
     margin-top: -15px;
    width: 20%;
    z-index: 999 !important;
 }
 
 @media (max-width: 500px) {
     .navbar-nav .dropdown-menu{
         position: absolute;
         left: 55vw;
         top: 8vh;
          margin-top: -15px;
         width: 20%;
        z-index: 999 !important;
      }
 }
 
 @media (max-width: 768px) {
     .navbar .navbar-brand{
        margin-left: 4rem;
      }
 } */
 .drp-dwn{
    padding-top: 7px;
    padding-bottom: 7px;
    /* margin-right: 20px; */
    border: none;
   
   }

 .drp-dwn:hover{
  padding-top: 7px;
  padding-bottom: 7px;
  border: none;
 
 }



@media (max-width: 991px) {
    .top-wrapper {
        width: 100%
     }
}